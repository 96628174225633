import dynamic from "next/dynamic";
import { ComponentLibrary } from "design-system/components";

export const frontendComponents = {
  // TOPPERS
  ArticleTopper: dynamic(() =>
    import(
      "design-system/components/toppers/article-topper/article-topper"
    ).then((e) => ({
      default: e.ArticleTopper,
    })),
  ),
  CardTopper: dynamic(() =>
    import("design-system/components/toppers/card-topper/card-topper").then(
      (e) => ({ default: e.CardTopper }),
    ),
  ),
  CourseTopper: dynamic(() =>
    import("design-system/components/toppers/course-topper/course-topper").then(
      (e) => ({ default: e.CourseTopper }),
    ),
  ),
  EditorialTopper: dynamic(() =>
    import(
      "design-system/components/toppers/editorial-topper/editorial-topper"
    ).then((e) => ({
      default: e.EditorialTopper,
    })),
  ),
  EventTopper: dynamic(() =>
    import("design-system/components/toppers/event-topper/event-topper").then(
      (e) => ({ default: e.EventTopper }),
    ),
  ),
  MissionTopper: dynamic(() =>
    import("design-system/sites/home/mission-topper/mission-topper").then(
      (e) => ({ default: e.MissionTopper }),
    ),
  ),
  MediaTopper: dynamic(() =>
    import("design-system/components/toppers/media-topper/media-topper").then(
      (e) => ({ default: e.MediaTopper }),
    ),
  ),
  ParticipantStoryTopper: dynamic(() =>
    import(
      "design-system/components/toppers/participant-story-topper/participant-story-topper"
    ).then((e) => ({
      default: e.ParticipantStoryTopper,
    })),
  ),
  ProgramTopper: dynamic(() =>
    import("design-system/sites/exed/program-topper/program-topper").then(
      (e) => ({
        default: e.ProgramTopper,
      }),
    ),
  ),
  ProfileTopper: dynamic(() =>
    import(
      "design-system/components/toppers/profile-topper/profile-topper"
    ).then((e) => ({
      default: e.ProfileTopper,
    })),
  ),
  SearchTopper: dynamic(() =>
    import("design-system/components/toppers/search-topper/search-topper").then(
      (e) => ({ default: e.SearchTopper }),
    ),
  ),
  SimplePageTopper: dynamic(() =>
    import(
      "design-system/components/toppers/simple-page-topper/simple-page-topper"
    ).then((e) => ({
      default: e.SimplePageTopper,
    })),
  ),
  SimpleProgramTopper: dynamic(() =>
    import(
      "design-system/components/toppers/simple-program-topper/simple-program-topper"
    ).then((e) => ({
      default: e.SimpleProgramTopper,
    })),
  ),
  SplitTopper: dynamic(() =>
    import("design-system/components/toppers/split-topper/split-topper").then(
      (e) => ({ default: e.SplitTopper }),
    ),
  ),
  StatisticsTopper: dynamic(() =>
    import(
      "design-system/components/toppers/statistics-topper/statistics-topper"
    ).then((e) => ({
      default: e.StatisticsTopper,
    })),
  ),
  LargeAssetTopper: dynamic(() =>
    import(
      "design-system/components/toppers/large-asset-topper/large-asset-topper"
    ).then((e) => ({
      default: e.LargeAssetTopper,
    })),
  ),
  FeaturedTermTopper: dynamic(() =>
    import(
      "design-system/sites/hbswk/featured-term-topper/featured-term-topper"
    ).then((e) => ({
      default: e.FeaturedTermTopper,
    })),
  ),
  SelectTopper: dynamic(() =>
    import("design-system/components/toppers/select-topper/select-topper").then(
      (e) => ({
        default: e.SelectTopper,
      }),
    ),
  ),

  // TEASES
  ArticleTease: dynamic(() =>
    import(
      "design-system/components/primitives/article-tease/article-tease"
    ).then((e) => ({
      default: e.ArticleTease,
    })),
  ),
  BookTease: dynamic(() =>
    import("design-system/components/primitives/book-tease/book-tease").then(
      (e) => ({
        default: e.BookTease,
      }),
    ),
  ),
  CourseCardTeaseRow: dynamic(() =>
    import(
      "design-system/sites/online/components/course-card-tease-row/course-card-tease-row"
    ).then((e) => ({
      default: e.CourseCardTeaseRow,
    })),
  ),
  EventFeedTease: dynamic(() =>
    import(
      "design-system/components/primitives/event-feed-tease/event-feed-tease"
    ).then((e) => ({
      default: e.EventFeedTease,
    })),
  ),
  ParticipantStoryTease: dynamic(() =>
    import(
      "design-system/components/primitives/participant-story-tease/participant-story-tease"
    ).then((e) => ({
      default: e.ParticipantStoryTease,
    })),
  ),
  PersonSearchResultTease: dynamic(() =>
    import(
      "design-system/components/primitives/person-search-result-tease/person-search-result-tease"
    ).then((e) => ({
      default: e.PersonSearchResultTease,
    })),
  ),
  PersonTease: dynamic(() =>
    import(
      "design-system/components/primitives/person-tease/person-tease"
    ).then((e) => ({
      default: e.PersonTease,
    })),
  ),
  PodcastTease: dynamic(() =>
    import(
      "design-system/components/primitives/podcast-tease/podcast-tease"
    ).then((e) => ({
      default: e.PodcastTease,
    })),
  ),
  QuoteTease: dynamic(() =>
    import("design-system/components/primitives/quote-tease/quote-tease").then(
      (e) => ({
        default: e.QuoteTease,
      }),
    ),
  ),
  ProgramTease: dynamic(() =>
    import(
      "design-system/components/primitives/program-tease/program-tease"
    ).then((e) => ({
      default: e.ProgramTease,
    })),
  ),
  SocialMediaTease: dynamic(() =>
    import(
      "design-system/components/primitives/social-media-tease/social-media-tease"
    ).then((e) => ({
      default: e.SocialMediaTease,
    })),
  ),
  VideoTease: dynamic(() =>
    import("design-system/components/primitives/video-tease/video-tease").then(
      (e) => ({
        default: e.VideoTease,
      }),
    ),
  ),

  // SITE HEADERS
  SiteHeader: dynamic(() =>
    import("design-system/components/universal/site-header/site-header").then(
      (e) => ({
        default: e.SiteHeader,
      }),
    ),
  ),
  OnlineSiteHeader: dynamic(() =>
    import("design-system/sites/online/site-header/online-site-header").then(
      (e) => ({
        default: e.OnlineSiteHeader,
      }),
    ),
  ),
} satisfies ComponentLibrary;
