export type Translations = Record<string, Record<string, string>>;

export const translations: Translations = {
  "en-us": {
    dates: "Dates",
    status: "Status",
    format: "Format",
    blended: "Blended",
    inPerson: "In-Person",
    virtual: "Virtual",
    location: "Location",
    role: "Role",
    industry: "Industry",
    program: "Program",
    fee: "Fee",
    applicationDue: "Application Due",
    overview: "Overview",
    selectSession: "Select a Session",
    learningTrack: "Learning Track",
    startDateToBeConfirmed: "Start Date to Be Confirmed",
    toBeConfirmed: "To Be Confirmed",
    lastRan: "Last Ran",
    applyNow: "Apply Now",
    downloadBrochure: "Download Brochure",
    backToText: "back to text",
    programFormat: "Program Format",
  },
  "zh-cn": {
    dates: "日期",
    status: "申请状态",
    format: "形式",
    blended: "混合",
    inPerson: "面对面学习",
    virtual: "虚拟",
    location: "地点",
    role: "职能",
    industry: "行业",
    program: "课程",
    fee: "费用",
    applicationDue: "申请截止日期",
    overview: "概述",
    selectSession: "选择一个会话",
    learningTrack: "学习轨迹",
    startDateToBeConfirmed: "Start Date 待定",
    toBeConfirmed: "待定",
    lastRan: "上次运行",
    applyNow: "立即申请",
    downloadBrochure: "下载课程手册",
    backToText: "返回文本",
    programFormat: "课程形式",
  },
};
